/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        // Scroll to top
        $('.back-to-top a').click(function(e) {
          e.preventDefault();
          $('html, body').animate({
             scrollTop: 0
          });
        });

        // Images com moldura
        $('img.moldura').each(function(n, el){
          var wrap = $('<div />').attr('class', $(this).attr('class')).addClass('wrap-moldura').removeClass('moldura');
          $(this).wrap(wrap);
        });

        $window = $( window ).width();
        // menu mobile
        // $(document).on('click touchstart','.load-more' ,
        $(document).on('click touchstart', 'button.navbar-toggler', function() {
          if ($('button.navbar-toggler').hasClass('collapsed')){
            $('.banner').removeClass('mobile_open');
          } else {
            $('.banner').addClass('mobile_open');
            
          }
        });

        var lang = $('html').attr('lang');
        if (lang === "en-US") {
          //console.log(lang);
          $('.breadcrumb').addClass('breadcrumb-en');
          $('.gform_wrapper .alt>label').append('<span>ALTERNATIVE</span>');
        } else {
          $('.gform_wrapper .alt>label').append('<span>ALTERNATIVA</span>');
        }


        if ($window < 768) {
          // $('.menu-item-has-children > a').addClass('navbar-toggler');
          // $('.menu-item-has-children > a').attr('data-toggle', 'collapse');
          $('.menu-item-has-children > a').each(function( index ) {
            $(this).addClass('navbar-toggler');
            $(this).attr('data-toggle', 'collapse');
            $(this).attr('data-target', '#submenu' + index);
            $(this).attr('aria-controls', 'submenu' + index);
            $(this).attr('aria-expanded', 'false');
            //console.log( index + ": " + $( this ).text() );
          });
          //$('.menu-item-has-children > a').attr('data-target', '#submenu');
         // $('.menu-item-has-children > a').attr('aria-expanded', 'false');
          //$('.menu-item-has-children > a').attr('aria-controls', 'submenu');
          $('.sub-menu').each(function( index ) {
            $(this).attr('id', 'submenu' + index);
            $(this).addClass('collapse navbar-collapse menu-content');
            //console.log( index + ": " + $(this).text() );
          });
          //$('.sub-menu').attr('id', 'submenu');
          //$('.sub-menu').addClass('collapse navbar-collapse menu-content');
        }        
        //navbar-collapse
        $('.slick-mobile').slick({
          dots: true,
          arrows: false,
          infinite: true,
          mobileFirst: true,
          appendDots: $('.slide-dots-2'),
          responsive: [
             {
              breakpoint: 991,
              settings: "unslick"
             }
          ]

        });        
        if ($window < 768) {
          $.fn.ulSelect = function(){
            var ul = $(this);
            if (!ul.hasClass('ul-select')) {
              ul.addClass('ul-select');
            }
            ul.on('click', 'li', function(event) {
              event.preventDefault();
              /* Act on the event */
              //console.log(ul);
              ul.toggleClass('active');
            });
            
          };
          // Run
          $('#tabs-titles').ulSelect();
          $('#tabs-galeria').ulSelect();
          $('#book-chapters').ulSelect();
        }


        // JavaScript to be fired on all pages

        $('.tab-item.idade-do-ferro ').addClass('active');
        $('ul.tabs-timeline li a').on('click', function(e){
          e.preventDefault();

         // console.log( $(this).attr('rel') );

          var slide = $('div.' + $(this).attr('rel'));
          var index = $(slide).parent().parent().data('slick-index');

          //console.log(slide);

          $('.timeline-carousel').slick('slickGoTo', index);

          $('ul.tabs-timeline li').removeClass('active');
          $(this).parent().addClass('active');
        });

        // $('.timeline-carousel').slick('beforeChange'){
        //   var category_slick = $('.slick-current .slide-item').data('category');
        //    console.log(category_slick);
        // }
        var page = $('.pagination-container .pagination li');
        //console.log(page);
        page.each(function() {
          var the_class = $(this).attr('class');
          var content = $(this).text();

          if (the_class === content) {
            $(this).addClass('active');
          }
        });


        $('.timeline-carousel').on('afterChange', function(event, slick, currentSlide, nextSlide){
          //console.log(nextSlide);
          var category_slick = $('.slick-current .slide-item').data('category');
         //  console.log($('.tabs-timeline .tab-item'));
          var res = category_slick.split(" ").map(function(el) { 
            return '.' + el; 
          });
          $('ul.tabs-timeline li').removeClass('active');
          $('.tabs-timeline .tab-item').filter(res.join()).addClass('active');
          if ($window < 768) {
            $('html, body').animate({
              scrollTop: $(".timeline").offset().top
            }, 500);
          }
        });

       

        $('.timeline-carousel').slick({
          arrows: true,
          appendArrows: $('.arrows'),
          nextArrow: '<i class="fa fa-chevron-right arrow-right"></i>',
          prevArrow: '<i class="fa fa-chevron-left arrow-left"></i>',
          //dots: true
          adaptiveHeight: true,
          zIndex: 99999999,
          useCSS: false
          // responsive: [
          //   {
          //     breakpoint: 768,
          //     settings: {
          //       adaptiveHeight: true
          //     }
          //   }
          // ],
        });

        
        $('.single article.post img').addClass('img-fluid');



        if ($('.page-destaque').hasClass('light')) {
            $('.breadcrumb').addClass('light');
        }

        //var indexToGet = $('.slider .slick-slide').index( $('.slide-item').data('category') );
        //console.log(indexToGet);
        $('a[data-slide]').click(function(e) {
           e.preventDefault();
           var slideno = $(this).data('slide');
           console.log(slideno);
           $('.timeline-carousel').slick('slickGoTo', slideno );
         });

        //$('#silde-pagination').detach().appendTo($('#slide-arrows'));

        $(function() {
          $('.mejs-overlay-loading').closest('.mejs-overlay').addClass('load'); //just a helper class

          var $video = $('div.video video');
          var vidWidth = $video.attr('width');
          var vidHeight = $video.attr('height');

          $(window).resize(function() {
            var targetWidth = $(this).width(); //using window width here will proportion the video to be full screen; adjust as needed
            $('div.video, div.video .mejs-container').css('height', Math.ceil(vidHeight * (targetWidth / vidWidth)));
          }).resize();
        });

        $('#tabs-titles li').first().addClass('active');
        $('#tab-content-general .tab-pane').first().addClass('active');


        $('.toggle').each(function () {
            var $this = $(this);
            var toggleitem = $this.data('target') == null ? $this.find('.toggled') : $this.data('target');
            
            $(document.body).mouseup(function (e){
                var container = $(toggleitem);
            
                if ((!container.is(e.target) && container.has(e.target).length === 0) && !$('.toggle').is(e.target)) {
                    container.addClass('closed');
                    $this.removeClass('open');
                }
            });
            
            $this.on("click", function (e) {
              
                e.stopPropagation();
                e.preventDefault();
                
                $this.toggleClass('open').attr('aria-expanded', $this.hasClass('open') ? "true" : "false");
                $(toggleitem).toggleClass('closed').attr('aria-hidden',$(toggleitem).hasClass('closed') ? "true" : "false");
                
                $('.toggle').not($this+','+$this.parents()).removeClass('open').attr('aria-expanded', "false");
                $('.toggled').not($(toggleitem)+','+$(toggleitem).parents()).addClass('closed').attr('aria-hidden', "true");
            });
        });

        $(function(){
          var hash = window.location.hash;
          if (hash) {
            $('html, body').animate({
              scrollTop: $("#tabs-galeria").offset().top
            },2000);
            $('a[href^="'+ hash +'"]').click();
          }
        });





        /* LOAD MORE POSTS */
        var ajaxurl = "/wp-admin/admin-ajax.php";
        $('.load-more-expo').on('click', function(e){
          e.preventDefault();
          $('#loading-exposicao').show();
          var cat = $(this).attr('id');
          //console.log(cat);
          var page = parseInt($('#container-years').data('page'));
          page++;
          var data = {
            'action': 'load_one_more_year',
            'page'  : page,
            'cat'   : cat
          };
          //console.log(data);
          $.post(ajaxurl, data, function(response) {
            //console.log(response);
            $(response).hide().appendTo("#container-years").fadeIn(1000);
            //$('#container-years').append(response);
            $('#container-years').data('page', page);
            $('#loading-exposicao').hide();

            // if(response.indexOf('no-more') !== -1){
            //     console.log(" found");
            //     $('.load-more-expo').fadeOut();
            // }

          });


        
          if ($('.more-years').hasClass('last-year')) {
            $(this).fadeOut();
          }

          //console.log(data);

          //$('#container-years').append('<div>2022</div>')
        });
        //$("li").click(function()
        //$(document).on('click touchstart','td.myClass',function()
       // $('.load-more').on('click', function(event) {
        $(document).on('click touchstart','.load-more' , function(event) {
          event.preventDefault();
          event.stopImmediatePropagation();
          /* Act on the event */
          $('#loading-tab').show();
          var cat = $('#tab-content-galeria').find('.active').attr('id');
          var cat = $(this).attr('id');
          var page = parseInt($("#container-tab-years."+cat+"").data('page'));
          page++;

          
          var data = {
            'action': 'load_one_more_year',
            'page'  : page,
            'cat'   : cat
          };
          $.post(ajaxurl, data, function(response) {
            $(response).hide().appendTo("#container-tab-years."+cat+"").fadeIn(1000);
            $("#container-tab-years."+cat+"").data('page', page);
            $('#loading-tab').hide();
          });
          if ($('.more-years').hasClass('last-year')) {
            $(this).fadeOut();
          }
        });


        $(".load-more-proj").on('click', function(e){
          
          var btn = $(this);

          $(btn).fadeOut('fast');
          $('#loading-projetos').show();

          var page = parseInt($(btn).data('page')) + 1;
          $(btn).data('page', page);
          
          var data = {
            'action': 'load_more_post',
            'page' : page
          };
          $.post(ajaxurl, data, function(response) {
            $(response).hide().appendTo(".item-projetos").fadeIn(500);
            $('#loading-projetos').hide();
            if ( parseInt($(btn).data('page')) < parseInt($(btn).data('max_num_pages')) ) {
              $(btn).fadeIn('fast');
            }

            var scroll_pos = $('.more-posts-new').first();
            if ( scroll_pos.length ) {
              
              $('html, body').animate({
                scrollTop: $(scroll_pos).offset().top - 150
              }, 500);
              
              $('.more-posts-new').removeClass('more-posts-new');
              
            }
            
          });
          
        });
       
        $('#collapseOne').on('hidden.bs.collapse', function (e) {
            //console.log('close');
            $('html, body').animate({
                scrollTop: $(".bottom-btn").offset().top
            }, 500);
        }); 

        $('#collapseOne').on('shown.bs.collapse', function (e) {
            //console.log('close');
            $('html, body').animate({
                scrollTop: $("#collapseOne").offset().top
            }, 500);
        }); 

        $('.slider-for').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.slider-nav',
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: true,
                nextArrow: '<div class="arrow-next mobile-arrow"><i class="fa fa-chevron-right arrow-right"></i></div>',
                prevArrow: '<div class="arrow-prev mobile-arrow"><i class="fa fa-chevron-left arrow-left"></i></div>',

              }
            },
             
          ]

        });
        $('.slider-nav').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: '.slider-for',
          dots: false,
          centerMode: true,
          focusOnSelect: true,
          nextArrow: '<div class="arrow-next"><i class="fa fa-chevron-right arrow-right"></i></div>',
          prevArrow: '<div class="arrow-prev"><i class="fa fa-chevron-left arrow-left"></i></div>',
        });

        $('.slider-for').on('afterChange', function(event, slick, currentSlide, nextSlide){
          $('video').trigger('pause');
        });

        //container
        $('form label a').attr({
          'data-toggle': 'modal',
          'data-target': '#exampleModalCenter'
        });

        $('form label a').data({
          toggle: 'modal',
          target: '#exampleModalCenter'
        });

         $('.gform_wrapper .add-asterisk label').append('<span class="gfield_required">*</span>');

         /*
            reset form
         */
         $('.marcar-visita').on('show.bs.modal', function (e) {
            $('.marcar-visita form').get(0).reset();
            $('.gfield_error').removeClass('gfield_error');
            $('.validation_error').remove();
            $('.validation_message').remove();
         });

         /*
            slider labels
         */
         $('.marcar-visita').on('shown.bs.modal', function (e) {
            
            if ( $('.marcar-visita').hasClass('labeled') ) {
               return;
            }

            $('label').each(function(){
               $(this).html( $(this).html().replace(/\(Alternativ.+?\)/, '')  );
            });

            $('input.datepicker').attr('autocomplete', 'off');
            $('.marcar-visita').addClass('labeled');
            var labels = $('<div class="labels"></div>');
            var padding = 100 / 7;
            for (var i = 1; i < 7; i++) {
               $(labels).append('<span class="step" style="width:' + padding + '%"><i>' + (i + 10 ) + 'H</i></span>');
            }
            
            $('.noUi-target').append(labels);
            
         });

         /*** validation field menor-18 ***/ 
         //$(".marcar-visita form input[type='submit']").on("click", function(){
         //
         //   var n_pessoas = $('.marcar-visita form .n-pessoas input').val();
         //   var menor_18 = $('.marcar-visita form .menor-18 input').val();
         //
         //   if (menor_18 >= n_pessoas) {
         //      $('.marcar-visita form .menor-18 .validation_message').remove();
         //      $('.marcar-visita form .menor-18').addClass('gfield_error');
         //      $('.marcar-visita form .menor-18').append('<div class="gfield_description validation_message">Número pessoas com menos de 18 anos deve ser menor que o número de pessoas total</div>')
         //      return(false);
         //   } 
         //
         //});


        $(document).on('submit','.marcar-visita form',function(){
            //$('.gform_wrapper input.datepicker').val($.datepicker.formatDate('dd-mm-yy', new Date())); 
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.destaque-carousel').slick({
          dots: true,
          arrows: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          //centerMode: false,
         // variableWidth: true,
          appendDots: $('.slide-dots'),
          zIndex: 99999999,
          responsive: [
            {
              breakpoint: 728,
              settings: {
               variableWidth: false
              }
            },
             
          ]
        });
          
        
        

        $window = $( window ).width();
        if ($window > 768) {
          $('.btn li').click(function(){
              $(this).toggleClass('active');
              $('.btn li').not(this).removeClass('active');
              
          });
        }
        if ($window > 991) {

          $('.accordion .ac-content').hide();
          $('.accordion.active .ac-content').show();

          $('.accordion').click(function(){
              $(this).toggleClass('active');
              $('.accordion').not(this).removeClass('active');
              $('.accordion .ac-content').hide(1000);
              $('.accordion.active .ac-content').show(1000);
          });
        }
        

    
        /*
          Animations
        */
        // var $animation_elements = $('#dashes');
        // var $window = $(window);

        // function check_if_in_view() {
        //   var window_height = $window.height();
        //   var window_top_position = $window.scrollTop();
        //   var window_bottom_position = (window_top_position + window_height);

        //   $.each($animation_elements, function() {
        //     var $element = $(this);
        //     var element_height = $element.outerHeight();
        //     var element_top_position = $element.offset().top;
        //     var element_bottom_position = (element_top_position + element_height);

        //     //check to see if this current container is within viewport
        //     if ((element_bottom_position >= window_top_position) &&
        //     (element_top_position <= window_bottom_position)) {
        //       $element.addClass('in-view');
        //     } else {
        //       $element.removeClass('in-view');
        //     }
        //   });
        // }

        // if ( $animation_elements.length ) {
        //   $window.on('scroll', check_if_in_view);
        //   $window.on('scroll resize', check_if_in_view);
        //   $window.trigger('scroll');
        // }

        if ($window > 991) {
          $('#parallax').mousemove(
            function(e){
                /* Detecta a posição do mouse */
                var offset = $(this).offset();
                var xPos = e.pageX - offset.left;
                var yPos = e.pageY - offset.top;

                /* Pega a posição em porcentagem */
                var mouseXPercent = Math.round(xPos / $(this).width() * 100);
                var mouseYPercent = Math.round(yPos / $(this).height() * 100);

                /* Posiciona cada camada */
                $(this).children('.dashes').each(
                    function(){
                        var diffX = $('#parallax').width() - $(this).width();
                        var diffY = $('#parallax').height() - $(this).height();

                        // console.log('x = '+diffX);
                        // console.log('y = '+diffY);

                        var myX = diffX * (mouseXPercent / 100); //) / 100) / 2;
                        var myY = diffY * (mouseYPercent / 100);

                        // console.log('myx = '+myX);
                        // console.log('myy = '+myY);

                        var cssObj = {
                            'left': myX + 'px',
                            'top': myY + 'px'
                        };
                        //$(this).css(cssObj);
                        $(this).animate({
                            left: myX, 
                            top: myY
                        },
                        {
                            duration: 50, 
                            queue: false, 
                            easing: 'linear'
                        });

                    }
                );

            }
          );
        }

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'galeria_millennium': {
      init: function() {
        $('#tabs-galeria li').first().addClass('active');
        $('#tab-content-galeria .tab-pane').first().addClass('active');
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'projetos_apoiados':{
      init: function() {
        
      }
    },
    'nucleo_arqueologico': {
      init: function() {
        //console.log('nucleo');
        $( ".breadcrumb" ).append( "<div class='complement'><span>Rua dos Correeiros (NARC) </span></div>" );

        gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {

          optionsObj.minDate = 0;
          optionsObj.firstDay = 1;
          optionsObj.beforeShowDay = function (date) {
              var disabledDays = ['01/12', //Janeiro 01 Terça-feira Dia de Ano Novo
                                  '05/02', //Fevereiro 05 Terça-feira Entrudo
                                  '18/04', //Abril 18 Quinta-feira Quinta-feira Santa
                                  '19/04', //Abril 19 Sexta-feira Sexta-feira Santa 
                                  '21/04', //Abril 21 Domingo Páscoa
                                  '25/04', //Abril 25 Quinta-feira Dia da Liberdade
                                  '01/05', //Maio 01 Quarta-feira Dia do Trabalhador
                                  '10/06', //Junho 10 Segunda-feira Dia de Portugal
                                  '13/06', //Junho13 Quinta-feira Dia de Santo António de lisboa
                                  '20/06', //Junho 20 Quinta-feira Corpo de Deus
                                  '15/08', //Agosto 15 Quinta-feira Assunção de Nossa Senhora
                                  '05/10', //Outubro 05 Sábado Implantação da República
                                  '01/11', //Novembro 01 Sexta-feira Dia de Todos os Santos
                                  '01/12', //Dezembro 01 Domingo Restauração da Independência
                                  '08/12', //Dezembro 08 Domingo Dia da Imaculada Conceição
                                  '24/12', //Dezembro 24 Terça-feira Véspera de Natal
                                  '25/12'],//Dezembro 25 Quarta-feira Dia de Natal

                  currentDate = jQuery.datepicker.formatDate('dd/mm', date),
                  day = date.getDay();
              //console.log(day);
              return [!(disabledDays.indexOf(currentDate) !== -1 || day === 0)]; //disable all sundays
              
          };
          //optionsObj.dateFormat = 'dd/m';
          return optionsObj;



        } );
      }
    },
    'como_visitar': {
      init: function(){

        var highest = null;
        var hi = 0;
        $(".visit-content").each(function(){
          var h = $(this).height();
          if(h > hi){
             hi = h;
             highest = $(this);  
          }    
        });
        //highest now contains the div with the highest so lets highlight it
       
        $(".visit-content").css('height', highest.height());

        gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {

          optionsObj.minDate = 0;
          optionsObj.firstDay = 1;
          optionsObj.beforeShowDay = function (date) {
              var disabledDays = ['01/12', //Janeiro 01 Terça-feira Dia de Ano Novo
                                  '05/02', //Fevereiro 05 Terça-feira Entrudo
                                  '18/04', //Abril 18 Quinta-feira Quinta-feira Santa
                                  '19/04', //Abril 19 Sexta-feira Sexta-feira Santa 
                                  '21/04', //Abril 21 Domingo Páscoa
                                  '25/04', //Abril 25 Quinta-feira Dia da Liberdade
                                  '01/05', //Maio 01 Quarta-feira Dia do Trabalhador
                                  '10/06', //Junho 10 Segunda-feira Dia de Portugal
                                  '13/06', //Junho13 Quinta-feira Dia de Santo António de lisboa
                                  '20/06', //Junho 20 Quinta-feira Corpo de Deus
                                  '15/08', //Agosto 15 Quinta-feira Assunção de Nossa Senhora
                                  '05/10', //Outubro 05 Sábado Implantação da República
                                  '01/11', //Novembro 01 Sexta-feira Dia de Todos os Santos
                                  '01/12', //Dezembro 01 Domingo Restauração da Independência
                                  '08/12', //Dezembro 08 Domingo Dia da Imaculada Conceição
                                  '24/12', //Dezembro 24 Terça-feira Véspera de Natal
                                  '25/12'],//Dezembro 25 Quarta-feira Dia de Natal

                  currentDate = jQuery.datepicker.formatDate('dd/mm', date),
                  day = date.getDay();
              //console.log(day);
              return [!(disabledDays.indexOf(currentDate) !== -1 || day === 0)]; //disable all sundays
              
          };
          optionsObj.dateFormat = 'dd/mm/yy';
          return optionsObj;



        } );
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
